<template>
  <section class="l-content">
    <el-card name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="记录状态">
          <el-radio-group v-model="condition.borrowState" @change="getPageData()">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="0">未提交</el-radio-button>
            <el-radio-button label="1">已提交</el-radio-button>
            <el-radio-button label="10">已审批</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="使用时间">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="资产名称/使用人姓名"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="workType" slot-scope="scope">
          <el-button type="text" @click="$site.view_myCarBorrow_detail(scope.row.borrowId)">{{ scope.row.workType }}</el-button>
        </template>
        <!--栏目插槽：计划出发时间/出发地-->
        <template slot="startTime" slot-scope="scope">
          <p>
            {{ scope.row.startTime | limitTo(16) }}
          </p>
          <p class="text-cut">
            {{ scope.row.startPlace }}
          </p>
        </template>
        <!--栏目插槽：计划返回时间/到达地-->
        <template slot="endTime" slot-scope="scope">
          <p>
            {{ scope.row.endTime | limitTo(16) }}
          </p>
          <p class="text-cut">
            {{ scope.row.endPlace }}
          </p>
        </template>
        <template slot="borrowState" slot-scope="scope">
          <span v-if="scope.row.borrowState == 0" class="text-blue">未提交</span>
          <span v-else-if="scope.row.borrowState == 1" class="text-orange">已提交</span>
          <span v-else-if="scope.row.borrowState == 10" class="text-green">已审批</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'MyCarBorrowList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '用车人', prop: 'userName' },
          { label: '车牌号', prop: 'carNo' },
          { label: '用车事由', slot: 'workType' },
          { label: '出发时间/地点', slot: 'startTime' },
          { label: '返回时间/到达地', slot: 'endTime' },
          {
            label: '表显里程',
            prop: 'endMileage',
            filter: (a, data) => {
              if (data.startMileage && data.endMileage) return data.endMileage - data.startMileage
              else return '---'
            }
          },
          { label: '费用', prop: 'feeTotal' },
          { label: '审批人', prop: 'verifyUserName' },
          { label: '状态', slot: 'borrowState' }
        ],
        operation: {
          width: '160',
          buttons: [{ type: 'primary', icon: 'el-icon-printer', label: '打印', click: this.print }]
        }
      },
      condition: {
        keyword: '',
        // enterpriseCode: this.userInfo.enterpriseCode,
        borrowState: '',
        startDate: '',
        endDate: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/user/mycar/borrow/pagelist', ['enterpriseCode', this.condition])
    },
    print(item) {
      if (item.borrowState !== 10) {
        this.$lgh.msgError('未审核无法打印')
        return
      }
      this.$site.view_myCarBorrow_Print(item.borrowId)
    }
  }
}
</script>
